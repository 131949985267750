import React, { useState, useEffect } from "react";
import { CookiesProvider, withCookies } from "react-cookie";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

import "styles/app.scss";
import CookiesBar from "./CookiesBar";

const Layout = ({ children, cookies, location, headerColor }) => {
	const [cookieBaner, setCookieBaner] = useState(true);

	useEffect(() => {
		const isAcceptedCoookie = cookies.get("thailandCookies");
		isAcceptedCoookie && setCookieBaner(false);
	}, [cookies]);

	const acceptCookieBaner = () => {
		const promiseSetCookie = new Promise((resolve) =>
			resolve(cookies.set("thailandCookies", "active", { path: "/" }))
		);
		promiseSetCookie.then(() => {
			setCookieBaner(false);
		});
	};
	return (
		<CookiesProvider>
			<GoogleReCaptchaProvider reCaptchaKey="6LeInogeAAAAAJbY_OC-UMZAS3uI5BhwDbylOAO2">
				<Header color={headerColor} />
				<main>{children}</main>
				{cookieBaner && <CookiesBar onClick={acceptCookieBaner} />}
				<Footer />
			</GoogleReCaptchaProvider>
		</CookiesProvider>
	);
};

export default withCookies(Layout);
