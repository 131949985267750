/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "./header.scss";

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import Hamburger from "../Hamburger/index";
import HeaderSearch from "./HeaderSearch/HeaderSearch";

const Header = ({ color }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const [search, setSearch] = useState(false);
	const [openedSubmenu, setOpenedSubmenu] = useState(0);
	const [isMobile, setIsMobile] = useState(false);
	const isBrowser = () => typeof window !== "undefined";
	const width = isBrowser() && window.innerWidth;

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setIsScrolled(window.scrollY > 20);
		});
		setIsMobile(width <= 575);
	}, [width]);

	return (
		<header
			className={classNames("header", {
				[`header--scrolled`]: isScrolled || isMenuOpen,
			})}
			style={{ backgroundColor: color }}
		>
			<div className="container">
				<Link to="/" className="header__logo-link">
					<img
						src={require("assets/images/logo-color.svg").default}
						alt=""
						className="header__logo"
					/>
				</Link>

				<nav
					className={
						isMenuOpen
							? "header__navigation header__navigation--active"
							: "header__navigation"
					}
					style={{ backgroundColor: color }}
				>
					<div
						className="header__nav-link header__nav-link--blue"
						onClick={() => isMobile && setOpenedSubmenu(1)}
					>
						Wszystko o tajlandii
						<div
							className={classNames(`header__hidden-menu`, {
								[`header__hidden-menu--active`]:
									openedSubmenu === 1,
							})}
						>
							<Link
								to="/kultura"
								className="header__hidden-nav-link header__hidden-nav-link--small header__hidden-nav-link--blue"
							>
								Kultura
							</Link>
							<Link
								to="/informacje-ogolne"
								className="header__hidden-nav-link header__hidden-nav-link--small header__hidden-nav-link--blue"
							>
								Informacje ogólne
							</Link>
						</div>
					</div>
					<div
						className="header__nav-link"
						onClick={() => isMobile && setOpenedSubmenu(2)}
					>
						Odkryj tajlandię
						<div
							className={classNames(`header__hidden-menu`, {
								[`header__hidden-menu--active`]:
									openedSubmenu === 2,
							})}
						>
							<Link
								to="/mapa-regionow"
								className="header__hidden-nav-link"
							>
								Mapa regionów
							</Link>
							<Link
								to="/dla-wielbicieli-plaz"
								className="header__hidden-nav-link"
							>
								Dla wielbicieli plaż
							</Link>
							<Link
								to="/zabytki"
								className="header__hidden-nav-link"
							>
								Zabytki
							</Link>
						</div>
					</div>
					<div
						className="header__nav-link header__nav-link--green"
						onClick={() => isMobile && setOpenedSubmenu(3)}
					>
						Dlaczego warto?
						<div
							className={classNames(`header__hidden-menu`, {
								[`header__hidden-menu--active`]:
									openedSubmenu === 3,
							})}
						>
							<Link
								to="/imprezy-i-festiwale"
								className="header__hidden-nav-link header__hidden-nav-link--green"
							>
								Imprezy i festiwale
							</Link>
							<Link
								to="/podroz-poslubna"
								className="header__hidden-nav-link header__hidden-nav-link--green"
							>
								Podróż poślubna
							</Link>
							<Link
								to="/tajskie-spa"
								className="header__hidden-nav-link header__hidden-nav-link--green"
							>
								Tajskie SPA
							</Link>
							<Link
								to="/rekreacja"
								className="header__hidden-nav-link header__hidden-nav-link--green"
							>
								Rekreacja
							</Link>
							<Link
								to="/jedzenie"
								className="header__hidden-nav-link header__hidden-nav-link--green"
							>
								Jedzenie
							</Link>
							<Link
								to="/zakupy"
								className="header__hidden-nav-link header__hidden-nav-link--green"
							>
								Zakupy
							</Link>
						</div>
					</div>
					<div
						className="header__nav-link header__nav-link--orange"
						onClick={(e) => isMobile && setOpenedSubmenu(4)}
					>
						Zanim wyruszysz
						<div
							className={classNames(`header__hidden-menu`, {
								[`header__hidden-menu--active`]:
									openedSubmenu === 4,
							})}
						>
							<Link
								to="/pieniadze-i-waluta"
								className="header__hidden-nav-link header__hidden-nav-link--orange"
							>
								Pieniądze i waluta
							</Link>
							<Link
								to="/visa-i-wstep-do-tajlandii"
								className="header__hidden-nav-link header__hidden-nav-link--orange"
							>
								VISA i wstęp do Tajlandii
							</Link>
						</div>
					</div>
					<div
						className="header__nav-link header__nav-link--dark-blue"
						onClick={() => isMobile && setOpenedSubmenu(5)}
					>
						Aktualności
						<div
							className={classNames(`header__hidden-menu`, {
								[`header__hidden-menu--active`]:
									openedSubmenu === 5,
							})}
						>
							<Link
								to="/wiadomosci-podroznicze/"
								className="header__hidden-nav-link header__hidden-nav-link--dark-blue"
							>
								Wiadomości podróżnicze
							</Link>
							<Link
								to="/tat-newsletters"
								onClick={() => setIsMenuOpen(false)}
								className="header__hidden-nav-link header__hidden-nav-link--dark-blue"
							>
								Newsletter
							</Link>
							{/* <a
                href="#newsletter"
                onClick={() => setIsMenuOpen(false)}
                className="header__hidden-nav-link header__hidden-nav-link--dark-blue"
              >
                Newsletter
              </a> */}
							<Link
								to="/blog/"
								className="header__hidden-nav-link header__hidden-nav-link--dark-blue"
							>
								Blog
							</Link>
						</div>
					</div>

					<div
						className="header__nav-link header__nav-link--violet"
						onClick={() => isMobile && setOpenedSubmenu(6)}
					>
						Dla partnerów
						<div
							className={classNames(`header__hidden-menu`, {
								[`header__hidden-menu--active`]:
									openedSubmenu === 6,
							})}
						>
							<Link
								to="/akademia-ekspertow"
								className="header__hidden-nav-link header__hidden-nav-link--violet"
							>
								Akademia ekspertów
							</Link>
							<Link
								to="/materialy-informacyjne"
								className="header__hidden-nav-link header__hidden-nav-link--violet"
							>
								E-broszury
							</Link>
							<Link
								to="/galeria"
								className="header__hidden-nav-link header__hidden-nav-link--violet"
							>
								Galeria
							</Link>
							<Link
								to="/kontakt"
								className="header__hidden-nav-link header__hidden-nav-link--violet"
							>
								Kontakt
							</Link>
						</div>
					</div>

					{search && <HeaderSearch />}
				</nav>
				<div className="header__toolbar-container">
					<div className="header__icons-container">
						<button
							type="button"
							onClick={() => setSearch(!search)}
						>
							<img
								src={require("assets/icons/search.svg").default}
								alt=""
							/>
						</button>
						<a
							href="https://www.facebook.com/AmazingThailandPoland"
							target="_blank"
							rel="noreferrer"
							className="header__social-link"
						>
							<img
								src={
									require("assets/icons/facebook-icon-new.svg")
										.default
								}
								alt=""
							/>
						</a>
						<a
							href="https://www.instagram.com/amazingthailand_pl/"
							target="_blank"
							rel="noreferrer"
							className="header__social-link"
						>
							<img
								src={
									require("assets/icons/instagram-icon-new.svg")
										.default
								}
								alt=""
							/>
						</a>
					</div>
					<div className="header__hamburger-container">
						<Hamburger
							onClick={(e) => setIsMenuOpen(!isMenuOpen)}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
