import "./footer.scss";
import React from "react";
import { Link } from "gatsby";
import Newsletter from "../Newsletter/Newsletter";

const Footer = () => {
  return (
    <>
      <div className="container">
        <Newsletter />
      </div>
      <footer className="footer" id="footer">
        <div className="container">
          <div className="footer__menu-row">
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Wszystko o tajlandii</div>
              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <Link to="/kultura" className="footer__menu-link">
                    Kultura
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/informacje-ogolne" className="footer__menu-link">
                    Informacje ogólne
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Odkryj tajlandię</div>
              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <Link to="/mapa-regionow" className="footer__menu-link">
                    Mapa regionów
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link
                    to="/dla-wielbicieli-plaz"
                    className="footer__menu-link"
                  >
                    Dla wielbicieli plaż
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/zabytki" className="footer__menu-link">
                    Zabytki
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Dlaczego warto?</div>
              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <Link to="/imprezy-i-festiwale" className="footer__menu-link">
                    Imprezy i festiwale
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/podroz-poslubna" className="footer__menu-link">
                    Podróż poślubna
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/tajskie-spa" className="footer__menu-link">
                    Tajskie spa
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/rekreacja" className="footer__menu-link">
                    Rekreacja
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/jedzenie" className="footer__menu-link">
                    Jedzenie
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/zakupy" className="footer__menu-link">
                    Zakupy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Zanim wyruszysz</div>
              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <Link to="/pieniadze-i-waluta" className="footer__menu-link">
                    Pieniądze i waluta
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link
                    to="/visa-i-wstep-do-tajlandii"
                    className="footer__menu-link"
                  >
                    VISA i wstęp do Tajlandii
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Aktualności</div>
              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <Link
                    to="/wiadomosci-podroznicze/"
                    className="footer__menu-link"
                  >
                    Wiadomości podróżnicze
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/tat-newsletters/" className="footer__menu-link">
                    Newsletter
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/blog/" className="footer__menu-link">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Dla partnerów</div>
              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <Link to="/akademia-ekspertow" className="footer__menu-link">
                    Akademia ekspertów
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link
                    to="/materialy-informacyjne"
                    className="footer__menu-link"
                  >
                    E-broszury
                  </Link>
                </li>
                <li className="footer__menu-item">
                  <Link to="/galeria" className="footer__menu-link">
                    Galeria
                  </Link>
                </li>
                <div className="footer__menu-subitem">
                  <div className="footer__menu-title footer__menu-title--mobile">
                    Dołącz do nas
                  </div>
                  <ul className="footer__menu footer__menu--social">
                    <li className="footer__menu-item footer__menu-item--social">
                      <a
                        href="https://www.facebook.com/AmazingThailandPoland"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src={
                            require("assets/icons/facebook-icon-new.svg")
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>

                    <li className="footer__menu-item footer__menu-item--social">
                      <a
                        href="https://www.instagram.com/amazingthailand_pl/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src={
                            require("assets/icons/instagram-icon-new.svg")
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div className="footer__menu-holder">
              <div className="footer__menu-title">Dołącz do nas</div>
              <ul className="footer__menu footer__menu--social">
                <li className="footer__menu-item footer__menu-item--social">
                  <a
                    href="https://www.facebook.com/AmazingThailandPoland"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={
                        require("assets/icons/facebook-icon-new.svg").default
                      }
                      alt=""
                    />
                  </a>
                </li>
                <li className="footer__menu-item footer__menu-item--social">
                  <a
                    href="https://www.instagram.com/amazingthailand_pl/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={
                        require("assets/icons/instagram-icon-new.svg").default
                      }
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__info-row">
            <div className="row justify-content-between">
              <div className="col-md-4">
                <div className="footer__copyright-container">
                  <img
                    src={require("assets/images/logo-white.svg").default}
                    alt=""
                    className="footer__thailand-logo"
                  />{" "}
                  <br />© {new Date().getFullYear()} Amazing Thailand. Wszelkie
                  prawa zastrzeżone. Żadna część tej strony nie może być
                  powielana bez naszej pisemnej zgody.
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer__text footer__text--tat">
                  TAT - Tourism Authority of Thailand (Prague)
                </div>
                <address className="footer__address">
                  Purkynova 2121/3 <br />
                  Quadrio Business Center <br />
                  Praga 11000, Republika Czeska
                </address>
                <div className="footer__text footer__text--areas">
                  <span>Obszary za które odpowiada TAT w Pradze:</span>
                  <br />
                  Czechy, Słowacja, Węgry, Polska, Ukraina, Rumunia, Bułgaria
                  oraz Bałkany
                </div>
                <div className="footer__contact-container">
                  <div className="footer__contact-item">
                    Telefon: <br />
                    <a href="tel:281913738-9" className="footer__contact-link">
                      + 420 / 281913738-9
                    </a>
                  </div>
                  <div className="footer__contact-item">
                    Email: <br />
                    <a
                      href="mailto:tatprague@tat.or.th"
                      className="footer__contact-link"
                    >
                      tatprague@tat.or.th
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer__embassy-info">
                  <div className="footer__text">
                    <a
                      href="https://warsaw.thaiembassy.org/pl/index"
                      target="_blank"
                      rel="noreferrer nofollow"
                      style={{ color: "#fff" }}
                    >
                      Ambasada Królestwa Tajlandii
                    </a>
                    <address className="footer__address">
                      ul. Willowa 7 <br /> 00-790 Warszawa
                    </address>
                  </div>
                </div>
                <div className="footer__contact-container footer__contact-container--embassy">
                  <div className="footer__contact-item">
                    Telefon: <br />
                    <a href="tel:8492655">(48-22) 849-26-55</a> <br />
                    <a href="tel:8496414">(48-22) 849-64-14</a>
                    <br />
                    <a href="tel:8491406">(48-22) 849-14-06</a>
                  </div>
                  <div className="footer__contact-item">
                    Email: <br />
                    <a href="mailto:contact@thaiemb.pl">contact@thaiemb.pl</a>
                  </div>
                </div>
                <div className="footer__embassy-info">
                  <div className="footer__text footer__text__terms">
                    <Link to="/regulamin-konkursu" style={{ color: "#fff" }}>
                      Regulamin konkursu
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3 footer__author-col">
                <a
                  href="https://adream.pl/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__author"
                >
                  Proudly designed by{" "}
                  <span className="footer__logo-author">ADream</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer__bottom-img">
        <div className="container">
          <div className="footer__copyright-container">
            <img
              src={require("assets/images/logo-white.svg").default}
              alt=""
              className="footer__thailand-logo"
            />{" "}
            <br />© 2021 Amazing Thailand. Wszelkie prawa zastrzeżone.Żadna
            część tej strony nie może być powielana bez naszej pisemnej zgody.
          </div>
          <a
            href="https://adream.pl/"
            target="_blank"
            rel="noreferrer"
            className="footer__author"
          >
            Proudly designed by ADream
          </a>
        </div>
      </div> */}
      </footer>
    </>
  );
};

export default Footer;
