import "./cookies-bar.scss";
import React from "react";
import { Link } from "gatsby";

const CookiesBar = ({ onClick }) => {
  return (
    <div className="cookies-bar">
      <div className="container">
        <img
          src={require("assets/icons/cookies.svg").default}
          alt=""
          className="cookies-bar__icon"
        />
        <div className="cookies-bar__text">
          Aby zoptymalizować naszą stronę internetową i stale ją ulepszać,
          używamy plików cookie.
        </div>
        <div className="cookies-bar__btn-container">
          <button
            onClick={onClick}
            className="button button--white"
            type="button"
          >
            Akceptuje
          </button>
          <Link to="/polityka-cookies" className="button button--outline">
            Czytaj więcej
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CookiesBar;
