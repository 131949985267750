import "./newsletter.scss";
import axios from "axios";
import React, { useState } from "react";
import { navigate } from "gatsby";
const mainClass = "newsletter";

const Newsletter = () => {
	const [email, setEmail] = useState("");

	const resetForm = () => {
		setEmail("");
	};

	const formSubmit = (e) => {
		e.preventDefault();

		let formData = new FormData();

		formData.set("email", email);

		axios({
			method: "post",
			url: "https://wp.amazingthailand.pl/wp-json/contact-form-7/v1/contact-forms/2039/feedback",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(() => {
				console.log("Submit success");
				resetForm();
				navigate("/thank-you-newsletter-page/");
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<>
			<span className="anchor" id="newsletter"></span>
			<div className={`${mainClass}`}>
				<div className="row g-0 align-items-center">
					<div className="col-md-8">
						<h2 className={`${mainClass}__title`}>
							Zapisz się do naszego newslettera
						</h2>
						<div className={`${mainClass}__desc`}>
							Dowiedź się o najnowszych wydarzeniach i nowościach
						</div>
						<form
							onSubmit={formSubmit}
							className={`${mainClass}__form`}
						>
							<input
								type="email"
								name="email"
								id="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Podaj swój adres e-mail"
							/>
							<button
								className="button button--white newsletter__button"
								type="submit"
							>
								Zapisz się
							</button>
						</form>
					</div>
					<div className="col-md-4">
						<div className={`${mainClass}__image`} />
					</div>
				</div>
			</div>
		</>
	);
};

export default Newsletter;
