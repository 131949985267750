import "./header-search.scss";

import React from "react";
import { Field, Formik } from "formik";
import { useQueryParam, StringParam } from "use-query-params";
import { navigate } from "gatsby";

const HeaderSearch = () => {
	const [search, setSearch] = useQueryParam("search", StringParam);

	const onSubmit = (values) => {
		setSearch(values.search);
		navigate(`/wyszukiwanie/` + document.location.search);
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={{
				search,
			}}
		>
			{({ handleSubmit }) => (
				<form className="header-search" onSubmit={handleSubmit}>
					<Field
						type="text"
						name="search"
						placeholder="Jakie informacje Cię interesują?"
						className="header-search__input"
					/>

					<button type="submit" className="header-search__button">
						Search
					</button>
				</form>
			)}
		</Formik>
	);
};

export default HeaderSearch;
